import axios from "axios";
import * as configs from "../configs";
import { GA_SEND, GA_TRACKING_ID, GA_WHATSAPP_EVENT } from "./analytics";

const api = getAPI();

function getAPI()
{
	return axios.create({
		baseURL: configs.SERVER.HOSTNAME
	});
}

function whatsAppSend(number: string = "5511992449217")
{
	const url = `https://api.whatsapp.com/send?phone=${number}&text=Clique%20no%20bot%C3%A3o%20ao%20lado%20para%20falar%20com%20o%20GrupoD%C3%AAOuvidos%20%3E%3E%3E`;

    // Após abrir a janela, envie o evento ao GA
	GA_SEND("conversion", `${GA_TRACKING_ID}/${GA_WHATSAPP_EVENT}`, () => {
		console.log("Evento enviado para o GA");
	});

    // Abra a nova aba/janela diretamente
    window.open(url, "_blank", "noopener,noreferrer");
}

function emailSend(email: string = "contato@grupodeouvidos.com.br")
{
	// ENVIAR CONVERSAO ?
	window.open(`mailto:${email}`, "_blank", "noopener,noreferrer");
}

function phoneSend(number: string)
{
	// ENVIAR CONVERSAO?
	window.open(`tel:${number}, "_blank", "noopener,noreferrer"`)
}

export default {
	api,
	whatsAppSend,
	emailSend,
	phoneSend
}