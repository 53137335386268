declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

export const GA_TRACKING_ID = 'AW-827429632';
export const GA_WHATSAPP_EVENT = "mFjDCIiA1awDEICmxooD";
export const GA_FORM_DEFAULT_EVENT = "4tviCJTT7YkBEICmxooD";
export const GA_FORM_TESTE7DIAS_EVENT = "cnxkCKfR7YkBEICmxooD";

// Verifica se o gtag foi carregado
const isGtagLoaded = (): boolean => {
	return typeof window !== 'undefined' && typeof window.gtag === 'function';
};

// Função para registrar visualizações de página
export const GA_PAGEVIEW = (url: string): void => {
	if (!isGtagLoaded()) return;
	
	window.gtag('config', GA_TRACKING_ID, {
		page_path: url,
	});
};

// Função para registrar eventos personalizados
export const GA_SEND = (action: "conversion", send_to: string, callback: any = null): void => {
    if (!isGtagLoaded()) {
        console.warn("Gtag não está carregado.");
        return;
    }

    const eventParams: Record<string, any> = {
        send_to,
    };

    if (callback && typeof callback === "function") {
        eventParams.event_callback = callback;
    }

    window.gtag('event', action, eventParams);
};